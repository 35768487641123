
const initState = () => ({
    productDetail: null,
    isLoadingImage: false,
})

export const state = initState

export const getters = {
  getIsLoadingImage(state){
    return state.isLoadingImage;
  }
}

export const mutations = {
    setProduct(state, {product}){
        state.productDetail = product
    },
    deleteProduct(state){
        state.productDetail = null
    },
    setIsLoadingImage(state,value){
      state.isLoadingImage = value;
    }
}

export const actions = {

}
