const initState = () => ({
  catalogs: [],
  page: 1,
  totalItems: 0,
  options: {},
})

export const state = initState

export const mutations = {
  setCatalogs(state, {catalogs}) {
    state.catalogs = catalogs
  },
  setOptions(state, {options}) {
    state.options = options
  },
  setTotalItems(state, {totalItems}) {
    state.totalItems = totalItems
  },
}

export const actions = {
  async fetchCatalogs({commit, state}, {filters}) {
    const {page, itemsPerPage} = state.options
    let url = `/SEO/catalogs?pageSize=${itemsPerPage ? itemsPerPage : 10}&pageNo=${ page ? page : 1}${filters}`
    const result = await this.$axios.$get(url)
    if(!result.hasErrors){
      commit('setCatalogs', {catalogs: result.value.items})
      commit('setTotalItems', {totalItems: result.value.pagingData.itemCount})
    }

    return result
  }
}
