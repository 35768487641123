import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e8946b12 = () => interopDefault(import('..\\pages\\dev-help\\index.vue' /* webpackChunkName: "pages/dev-help/index" */))
const _4ca8cfb2 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _ae4f1588 = () => interopDefault(import('..\\pages\\logout.vue' /* webpackChunkName: "pages/logout" */))
const _3f9314ae = () => interopDefault(import('..\\pages\\backoffice\\catalog\\index.vue' /* webpackChunkName: "pages/backoffice/catalog/index" */))
const _7a401c1e = () => interopDefault(import('..\\pages\\backoffice\\configuration\\index.vue' /* webpackChunkName: "pages/backoffice/configuration/index" */))
const _a7d857da = () => interopDefault(import('..\\pages\\backoffice\\dashboard\\index.vue' /* webpackChunkName: "pages/backoffice/dashboard/index" */))
const _43e67195 = () => interopDefault(import('..\\pages\\backoffice\\media-library\\index.vue' /* webpackChunkName: "pages/backoffice/media-library/index" */))
const _288f8276 = () => interopDefault(import('..\\pages\\backoffice\\my-profile\\index.vue' /* webpackChunkName: "pages/backoffice/my-profile/index" */))
const _00116568 = () => interopDefault(import('..\\pages\\backoffice\\product-catalog\\index.vue' /* webpackChunkName: "pages/backoffice/product-catalog/index" */))
const _0b306c6a = () => interopDefault(import('..\\pages\\backoffice\\seo\\index.vue' /* webpackChunkName: "pages/backoffice/seo/index" */))
const _89b321bc = () => interopDefault(import('..\\pages\\backoffice\\shopping-cart\\index.vue' /* webpackChunkName: "pages/backoffice/shopping-cart/index" */))
const _03acd4b0 = () => interopDefault(import('..\\pages\\backoffice\\cms\\components\\index.vue' /* webpackChunkName: "pages/backoffice/cms/components/index" */))
const _2b802824 = () => interopDefault(import('..\\pages\\backoffice\\cms\\contents\\index.vue' /* webpackChunkName: "pages/backoffice/cms/contents/index" */))
const _25c3281a = () => interopDefault(import('..\\pages\\backoffice\\financial\\customer-credits\\index.vue' /* webpackChunkName: "pages/backoffice/financial/customer-credits/index" */))
const _35001850 = () => interopDefault(import('..\\pages\\backoffice\\financial\\invoices\\index.vue' /* webpackChunkName: "pages/backoffice/financial/invoices/index" */))
const _013d359e = () => interopDefault(import('..\\pages\\backoffice\\financial\\payments\\index.vue' /* webpackChunkName: "pages/backoffice/financial/payments/index" */))
const _5f3a37a7 = () => interopDefault(import('..\\pages\\backoffice\\order-creation\\estimate\\index.vue' /* webpackChunkName: "pages/backoffice/order-creation/estimate/index" */))
const _a9582c40 = () => interopDefault(import('..\\pages\\backoffice\\status-tracking\\job-orders\\index.vue' /* webpackChunkName: "pages/backoffice/status-tracking/job-orders/index" */))
const _15d2c39e = () => interopDefault(import('..\\pages\\backoffice\\status-tracking\\quotes\\index.vue' /* webpackChunkName: "pages/backoffice/status-tracking/quotes/index" */))
const _a3712aa2 = () => interopDefault(import('..\\pages\\backoffice\\status-tracking\\sales-orders\\index.vue' /* webpackChunkName: "pages/backoffice/status-tracking/sales-orders/index" */))
const _3b6533d7 = () => interopDefault(import('..\\pages\\backoffice\\order-creation\\custom-order\\item-type\\_id\\index.vue' /* webpackChunkName: "pages/backoffice/order-creation/custom-order/item-type/_id/index" */))
const _171a9b5a = () => interopDefault(import('..\\pages\\backoffice\\cms\\components\\_componentId.vue' /* webpackChunkName: "pages/backoffice/cms/components/_componentId" */))
const _6d972c93 = () => interopDefault(import('..\\pages\\backoffice\\cms\\contents\\_contentId.vue' /* webpackChunkName: "pages/backoffice/cms/contents/_contentId" */))
const _1999b7c5 = () => interopDefault(import('..\\pages\\backoffice\\order-creation\\custom-order\\_id.vue' /* webpackChunkName: "pages/backoffice/order-creation/custom-order/_id" */))
const _17cc4cff = () => interopDefault(import('..\\pages\\backoffice\\product-catalog\\_productCategoryId.vue' /* webpackChunkName: "pages/backoffice/product-catalog/_productCategoryId" */))
const _1712f602 = () => interopDefault(import('..\\pages\\backoffice\\product\\_id\\index.vue' /* webpackChunkName: "pages/backoffice/product/_id/index" */))
const _291ef748 = () => interopDefault(import('..\\pages\\backoffice\\catalog\\_id\\products\\index.vue' /* webpackChunkName: "pages/backoffice/catalog/_id/products/index" */))
const _d110fdc6 = () => interopDefault(import('..\\pages\\_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dev-help",
    component: _e8946b12,
    name: "dev-help___en"
  }, {
    path: "/login",
    component: _4ca8cfb2,
    name: "login___en"
  }, {
    path: "/logout",
    component: _ae4f1588,
    name: "logout___en"
  }, {
    path: "/backoffice/catalog",
    component: _3f9314ae,
    name: "backoffice-catalog___en"
  }, {
    path: "/backoffice/configuration",
    component: _7a401c1e,
    name: "backoffice-configuration___en"
  }, {
    path: "/backoffice/dashboard",
    component: _a7d857da,
    name: "backoffice-dashboard___en"
  }, {
    path: "/backoffice/media-library",
    component: _43e67195,
    name: "backoffice-media-library___en"
  }, {
    path: "/backoffice/my-profile",
    component: _288f8276,
    name: "backoffice-my-profile___en"
  }, {
    path: "/backoffice/product-catalog",
    component: _00116568,
    name: "backoffice-product-catalog___en"
  }, {
    path: "/backoffice/seo",
    component: _0b306c6a,
    name: "backoffice-seo___en"
  }, {
    path: "/backoffice/shopping-cart",
    component: _89b321bc,
    name: "backoffice-shopping-cart___en"
  }, {
    path: "/fr/dev-help",
    component: _e8946b12,
    name: "dev-help___fr"
  }, {
    path: "/fr/login",
    component: _4ca8cfb2,
    name: "login___fr"
  }, {
    path: "/fr/logout",
    component: _ae4f1588,
    name: "logout___fr"
  }, {
    path: "/backoffice/cms/components",
    component: _03acd4b0,
    name: "backoffice-cms-components___en"
  }, {
    path: "/backoffice/cms/contents",
    component: _2b802824,
    name: "backoffice-cms-contents___en"
  }, {
    path: "/backoffice/financial/customer-credits",
    component: _25c3281a,
    name: "backoffice-financial-customer-credits___en"
  }, {
    path: "/backoffice/financial/invoices",
    component: _35001850,
    name: "backoffice-financial-invoices___en"
  }, {
    path: "/backoffice/financial/payments",
    component: _013d359e,
    name: "backoffice-financial-payments___en"
  }, {
    path: "/backoffice/order-creation/estimate",
    component: _5f3a37a7,
    name: "backoffice-order-creation-estimate___en"
  }, {
    path: "/backoffice/status-tracking/job-orders",
    component: _a9582c40,
    name: "backoffice-status-tracking-job-orders___en"
  }, {
    path: "/backoffice/status-tracking/quotes",
    component: _15d2c39e,
    name: "backoffice-status-tracking-quotes___en"
  }, {
    path: "/backoffice/status-tracking/sales-orders",
    component: _a3712aa2,
    name: "backoffice-status-tracking-sales-orders___en"
  }, {
    path: "/fr/backoffice/catalog",
    component: _3f9314ae,
    name: "backoffice-catalog___fr"
  }, {
    path: "/fr/backoffice/configuration",
    component: _7a401c1e,
    name: "backoffice-configuration___fr"
  }, {
    path: "/fr/backoffice/dashboard",
    component: _a7d857da,
    name: "backoffice-dashboard___fr"
  }, {
    path: "/fr/backoffice/media-library",
    component: _43e67195,
    name: "backoffice-media-library___fr"
  }, {
    path: "/fr/backoffice/my-profile",
    component: _288f8276,
    name: "backoffice-my-profile___fr"
  }, {
    path: "/fr/backoffice/product-catalog",
    component: _00116568,
    name: "backoffice-product-catalog___fr"
  }, {
    path: "/fr/backoffice/seo",
    component: _0b306c6a,
    name: "backoffice-seo___fr"
  }, {
    path: "/fr/backoffice/shopping-cart",
    component: _89b321bc,
    name: "backoffice-shopping-cart___fr"
  }, {
    path: "/fr/backoffice/cms/components",
    component: _03acd4b0,
    name: "backoffice-cms-components___fr"
  }, {
    path: "/fr/backoffice/cms/contents",
    component: _2b802824,
    name: "backoffice-cms-contents___fr"
  }, {
    path: "/fr/backoffice/financial/customer-credits",
    component: _25c3281a,
    name: "backoffice-financial-customer-credits___fr"
  }, {
    path: "/fr/backoffice/financial/invoices",
    component: _35001850,
    name: "backoffice-financial-invoices___fr"
  }, {
    path: "/fr/backoffice/financial/payments",
    component: _013d359e,
    name: "backoffice-financial-payments___fr"
  }, {
    path: "/fr/backoffice/order-creation/estimate",
    component: _5f3a37a7,
    name: "backoffice-order-creation-estimate___fr"
  }, {
    path: "/fr/backoffice/status-tracking/job-orders",
    component: _a9582c40,
    name: "backoffice-status-tracking-job-orders___fr"
  }, {
    path: "/fr/backoffice/status-tracking/quotes",
    component: _15d2c39e,
    name: "backoffice-status-tracking-quotes___fr"
  }, {
    path: "/fr/backoffice/status-tracking/sales-orders",
    component: _a3712aa2,
    name: "backoffice-status-tracking-sales-orders___fr"
  }, {
    path: "/fr/backoffice/order-creation/custom-order/item-type/:id",
    component: _3b6533d7,
    name: "backoffice-order-creation-custom-order-item-type-id___fr"
  }, {
    path: "/backoffice/order-creation/custom-order/item-type/:id",
    component: _3b6533d7,
    name: "backoffice-order-creation-custom-order-item-type-id___en"
  }, {
    path: "/fr/backoffice/cms/components/:componentId",
    component: _171a9b5a,
    name: "backoffice-cms-components-componentId___fr"
  }, {
    path: "/fr/backoffice/cms/contents/:contentId",
    component: _6d972c93,
    name: "backoffice-cms-contents-contentId___fr"
  }, {
    path: "/fr/backoffice/order-creation/custom-order/:id?",
    component: _1999b7c5,
    name: "backoffice-order-creation-custom-order-id___fr"
  }, {
    path: "/backoffice/cms/components/:componentId",
    component: _171a9b5a,
    name: "backoffice-cms-components-componentId___en"
  }, {
    path: "/backoffice/cms/contents/:contentId",
    component: _6d972c93,
    name: "backoffice-cms-contents-contentId___en"
  }, {
    path: "/backoffice/order-creation/custom-order/:id?",
    component: _1999b7c5,
    name: "backoffice-order-creation-custom-order-id___en"
  }, {
    path: "/fr/backoffice/product-catalog/:productCategoryId?",
    component: _17cc4cff,
    name: "backoffice-product-catalog-productCategoryId___fr"
  }, {
    path: "/fr/backoffice/product/:id",
    component: _1712f602,
    name: "backoffice-product-id___fr"
  }, {
    path: "/fr/backoffice/catalog/:id/products",
    component: _291ef748,
    name: "backoffice-catalog-id-products___fr"
  }, {
    path: "/backoffice/product-catalog/:productCategoryId?",
    component: _17cc4cff,
    name: "backoffice-product-catalog-productCategoryId___en"
  }, {
    path: "/backoffice/product/:id",
    component: _1712f602,
    name: "backoffice-product-id___en"
  }, {
    path: "/backoffice/catalog/:id/products",
    component: _291ef748,
    name: "backoffice-catalog-id-products___en"
  }, {
    path: "/fr/*",
    component: _d110fdc6,
    name: "all___fr"
  }, {
    path: "/*",
    component: _d110fdc6,
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
